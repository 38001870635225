import React from 'react';
import styles from './unleash-power.module.scss';
import Section from '@components/section';
import SectionHeading from '../section-heading';
import ContentInformation from '@components/content-information';
import IconCard from '../IconCard';

import ResponsiveCarousel from '../responsive-carousel';

const theme = {
	title: styles.title,
	subtitle: styles.subtitle
};

const carouselTheme = {
	container: styles.cardsGrid
};

const UnleashPower = ({
	sectionTitle,
	sectionSubtitle,
	imageTitle,
	imageSubtitle,
	imageFooterTitle,
	imageFooterDescription,
	imageSection,
	imageSectionMobile,
	...props
}) => {
	const expertTeam = props['expert-team'][0];
	return (
		<Section className={styles.container}>
			<SectionHeading element="h1" title={sectionTitle} subtitle={sectionSubtitle} theme={theme} />
			<section className={styles.imageContent}>
				<div className={styles.contentInfoContainer}>
					<ContentInformation className={styles.content} title={imageTitle} content={imageSubtitle} />
				</div>

				<div className={styles.imageContainer}>
					<div className={styles.image}>
						<img src={imageSection} alt="" />
					</div>

					<div className={styles.imageMobileContainer}>
						<img className={styles.imageMobile}  src={imageSectionMobile} alt="" />
					</div>

					<div className={styles.imageText}>
						<h2>{imageFooterTitle}</h2>
						<p>
							With a proven track record of delivering successful projects for Fortune 500 companies and
							startups alike,
							<b>
								our team of strategists, product managers, UI/UX designers, mobile developers, cloud
								architects, data scientists, full-stack developers, architects, and QA professionals
							</b>
							is well-equipped to handle the unique challenges and opportunities of the US market quickly
							and optimizing times.
						</p>
					</div>
				</div>

				<div className={styles.expertTeam}>
					{' '}
					<h1 className={styles.expertTeamTitle}>Expert Teams Ready to Boost Your Company's Productivity.</h1>
					<p className={styles.expertTeamSubtitle}>
						The augmented staff members work alongside the existing employees, under the company's supervision and direction, to fulfill specific roles or complete designated projects.
					</p>
					<ResponsiveCarousel theme={carouselTheme}>
						{expertTeam.items.map((item) => (
							<IconCard {...item} height={item.height} width={item.width} />
						))}
					</ResponsiveCarousel>
					<div className={styles.swipe}>
						<iframe
							className={styles.animation}
							src="https://lottie.host/?file=5b2f38d0-9678-4166-8d11-77726c512ad4/Z7lkPSY1s0.json"
						></iframe>
						<span className={styles.swipeText}>Swipe for more content</span>
						<svg xmlns="http://www.w3.org/2000/svg" width="67" height="8" viewBox="0 0 67 8" fill="none">
							<path
								d="M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646447 3.64645ZM66.3536 4.35355C66.5488 4.15829 66.5488 3.84171 66.3536 3.64645L63.1716 0.464466C62.9763 0.269204 62.6597 0.269204 62.4645 0.464466C62.2692 0.659728 62.2692 0.976311 62.4645 1.17157L65.2929 4L62.4645 6.82843C62.2692 7.02369 62.2692 7.34027 62.4645 7.53553C62.6597 7.7308 62.9763 7.7308 63.1716 7.53553L66.3536 4.35355ZM1 4.5H66V3.5H1V4.5Z"
								fill="#7C57F4"
							/>
						</svg>
					</div>
				</div>
			</section>
		</Section>
	);
};

export default UnleashPower;
