import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './trusted-by-nearshore.module.scss';
import Picture from '@components/picture';

const TrustedByNearshore = (props) => {
	const { title, subtitle, leads, caruselassets } = props;
	const [currentIndex, setCurrentIndex] = useState(0);

	const { name, description, icon } = leads[currentIndex];
	const { previous, next } = caruselassets[0];

	const nextSlide = () => {
		setCurrentIndex((prevIndex) => (prevIndex + 1) % leads.length);
	};

	const prevSlide = () => {
		setCurrentIndex((prevIndex) => (prevIndex - 1 + leads.length) % leads.length);
	};

	return (
		<div className={styles.container}>
			<div className={styles.mainContainer}>
				<div className={styles.titleContainer}>
					<div className={styles.titleContainer2}>
						<h1 className={styles.title}>{title}</h1>
					</div>
					<br />
					<p className={styles.subtitle}>{subtitle}</p>
				</div>

				<div className={styles.carrusel}>
					<img className={styles.imageCarrusel} src={icon} alt={name} />
					<div className={styles.descriptionContainer}>
						<p>{description}</p>
					</div>
				</div>

				<div className={styles.navigation}>
					<img src={previous} onClick={prevSlide}></img>
					<div className={`${styles.dot} ${currentIndex === 0 ? styles.active : ''}`}></div>
					<div className={`${styles.dot} ${currentIndex === 1 ? styles.active : ''}`}></div>
					<div className={`${styles.lastdot} ${currentIndex === 2 ? styles.active : ''}`}></div>
					<img src={next} onClick={nextSlide}></img>
				</div>
				<div className={styles.grayLine}></div>
			</div>
		</div>
	);
};

TrustedByNearshore.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
	leads: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			description: PropTypes.string.isRequired,
			icon: PropTypes.string.isRequired
		})
	).isRequired
};

export default TrustedByNearshore;
