import React from 'react';
import Layout from '@components/layout';
import HeadingNearshore from '@components/heading-nearshore';
import WeAreNearshore from '@components/we-are-nearshore';
import TrustedByNearshore from '@components/trusted-by-nearshore';
import UnleashPower from '@components/unleash-power';
import ThinksToKeepInMind from '@components/things-to-keep-in-mind';
import CarouselQuote from '@components/carousel-quote';
import { ContactUs } from '@components';
import LocationsNearshore from '@components/locations-nearshore';
import FeaturesNearshore from '@components/features-nearshore';
import HighPerformingNearshore from '@components/high-performing-nearshore';

const Nearshore = (props) => {
	const { location, pageContext } = props;

	return (
		<Layout location={location} pageContext={pageContext}>
			<HeadingNearshore {...pageContext['we-are']} />
			<WeAreNearshore {...pageContext['intro']} />
			<TrustedByNearshore {...pageContext['trusted-by']} />
			<HighPerformingNearshore {...pageContext['high-performing-teams']} />
			<FeaturesNearshore {...pageContext['product-features']} />
			<UnleashPower {...pageContext['unleash-the-power'][0]} />
			<ThinksToKeepInMind {...pageContext['things-to-keep-in-mind'][0]} />
			<CarouselQuote {...pageContext['reviews']} />
			<ContactUs bottom="250px" id="contact-from-product-strategy" pathname={location.pathname} />
		</Layout>
	);
};

export default Nearshore;
