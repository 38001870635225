import { Breakpoints, classNames } from '@utils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss';
import styles from './responsive-carousel.module.scss';

const ResponsiveCarousel = ({ children, theme }) => {
	const [swiperParams, setSwiperParams] = useState({});

	useEffect(() => {
		function getSwiper(swiper) {
			if (!swiper) {
				return false;
			}
			swiper.on('transitionEnd', () => {
				swiper.loopFix();
			});
		}

		setSwiperParams({
			loop: true,
			centeredSlides: true,
			slidesPerView: 'auto',
			getSwiper: getSwiper.bind(this)
		});
	}, [children]);

	return (
		<Breakpoints>
			{(breakpoint) => {
				const carousel = ['xs', 'sm'].includes(breakpoint);
				return (
					<div
						className={classNames(
							'locations-carousel',
							styles.locations,
							carousel && styles.locationsCarousel
						)}
					>
						{carousel && (
							<Swiper
								rebuildOnUpdate
								direction="horizontal"
								containerClass={styles.container}
								{...swiperParams}
							>
								{children}
							</Swiper>
						)}
						{!carousel && <div className={theme.container}>{children}</div>}
					</div>
				);
			}}
		</Breakpoints>
	);
};

ResponsiveCarousel.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default ResponsiveCarousel;
