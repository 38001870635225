import React from 'react';
import PropTypes from 'prop-types';
import styles from './we-are-nearshore.module.scss';

const WeAreNearshore = (props) => {
	const { sectionTitle, title } = props;

	return (
		<div className={styles.container}>
			<div className={styles.mainContainer}>
				<h5 className={styles.sectionTitle}>{sectionTitle}</h5>
				<h1 className={styles.title}>{title}</h1>
				<div className={styles.descriptionContainer}>
					<p className={styles.description}>
						Our product enthusiasts will become your team, providing you with the right blend of creative
						design and robust engineering. Through our nearshore software development services,{' '}
						<b>you will get senior talent to build digital products that your users will love.</b>
					</p>
					<p className={styles.description}>
						Being nearshore allows us to have much easier communication with the US; that along with our
						cultural similarities have been fundamental to form long term partnerships with{' '}
						<b>companies such as Bloomberg and Tony Robbins.</b>
					</p>
				</div>
			</div>
		</div>
	);
};

export default WeAreNearshore;
