import PropTypes from 'prop-types';
import React from 'react';
import styles from './content-information.module.scss';
import { classNames } from '@utils';

const ContentInformation = ({ className, title, content, theme }) => {
	return (
		<header className={classNames(className, styles.heading)}>
			<h3 className={classNames(theme?.title, styles.title)}>{title}</h3>
			<p className={classNames(theme?.content, styles.content)}>Partner with us to quickly and effectively <b>find the right talent for your digital product or software project</b>, and collaborate with us to achieve your goals.</p>
		</header>
	);
};

ContentInformation.propTypes = {
	theme: PropTypes.object,
	className: PropTypes.string,
	title: PropTypes.string,
	content: PropTypes.string
};

ContentInformation.defaultProps = {
	element: 'h1'
};

export default ContentInformation;
