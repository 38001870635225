import React from 'react';
import Link from '@components/link';
import Locations from '@components/locations';
import Phone from '@components/phone';
import footerSettings from '@static/cms/settings/footer.json';
import { classNames } from '@utils';
import PropTypes from 'prop-types';
import styles from './locations-nearshore.module.scss';
import IconCard from '../IconCard';
import ResponsiveCarousel from '../responsive-carousel';

const LocationsNearshore = ({ hideLocations, theme, ...props }) => {
	const { description, email, phone } = footerSettings;

	console.info(props);

	return (
		<footer className={classNames(styles.footer, theme && theme.container)}>
			{!hideLocations && (
				<div className={styles.locationsContainer}>
					<div className={styles.highlight}>
						<div className={styles.info}>
							<p className={styles.description}>{description}</p>
							<div>
								<Phone className={styles.phone} phone={phone} />
								<Link className={styles.email} to={`mailto:${email}`}>
									{email}
								</Link>
							</div>
						</div>
					</div>
				</div>
			)}
			<div className={styles.locationsGrid}>
				<ResponsiveCarousel theme={{ container: styles.locationsGrid }}>
					{Object.values(props).map((item) => (
						<IconCard className={styles.card} {...item} height={item.height} width={item.width}/>
					))}
				</ResponsiveCarousel>
			</div>
		</footer>
	);
};

LocationsNearshore.propTypes = {
	hideLocations: PropTypes.bool
};

LocationsNearshore.defaultProps = {
	hideLocations: false
};

export default LocationsNearshore;
