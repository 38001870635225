import PropTypes from 'prop-types';
import React from 'react';
import styles from './section-heading.module.scss';
import { classNames } from '@utils';

const elementHeading = {
	h1: ({ children, ...props }) => <h1 {...props}>{children}</h1>,
	h2: ({ children, ...props }) => <h2 {...props}>{children}</h2>,
	h3: ({ children, ...props }) => <h3 {...props}>{children}</h3>
};

const SectionHeading = ({ element, className, title, subtitle, theme }) => {
	const Heading = elementHeading[element];

	return (
		<header className={classNames(className, styles.heading)}>
			<Heading className={classNames(theme?.title, styles.title)}>{title}</Heading>
			<Heading className={classNames(theme?.subtitle, styles.subtitle)}>{subtitle}</Heading>
		</header>
	);
};

SectionHeading.propTypes = {
	element: PropTypes.oneOf(['h1', 'h2', 'h3']),
	theme: PropTypes.object,
	className: PropTypes.string,
	title: PropTypes.string,
	subtitle: PropTypes.string
};

SectionHeading.defaultProps = {
	element: 'h1'
};

export default SectionHeading;
