import React from 'react';
import styles from './things-to-keep-in-mind.module.scss';
import Section from '@components/section';
import SectionHeading from '../section-heading';
import ContentInformation from '../content-information';

const theme = {
	title: styles.title,
	subtitle: styles.subtitle
};

const ThinksToKeepInMind = ({ title, subtitle, items, ...props }) => {
	const technologies = props.technologies[0];
	return (
		<Section className={styles.container}>
			<SectionHeading element="h1" title={title} theme={theme} />
			<div className={styles.sutitleContainer}>
				<p className={styles.subtitle}>{subtitle}</p>
			</div>
			<span className={styles.separator} />

			<div className={styles.itemsGrid}>
				{items.map((item) => (
					<div className={styles.itemCard}>
						<img src={item.icon} alt="" />
						<h4>{item.title}</h4>
						<span>{item.description}</span>
						<i />
					</div>
				))}
			</div>

			<div className={styles.technologies}>
				<h3 className={styles.titleTech}>Technologies that we love</h3>
				<div className={styles.descriptionTech}>
					<p>
						Exploring the Technological Landscape: Harnessing the Power of Multiple Technologies for
						Enhanced Innovation and Efficiency
					</p>
				</div>

				<div className={styles.technologiesCarousel}>
					{technologies.items.map((item) => (
						<img src={item.icon} alt={item.name} />
					))}
				</div>
			</div>
		</Section>
	);
};

export default ThinksToKeepInMind;
