import React from 'react';
import PropTypes from 'prop-types';
import styles from './high-performing-nearshore.module.scss';

const HighPerformingNearshore = (props) => {
  const { title, description1, icon, iconMobile } = props;

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description1}</p>
        <br />
        <p className={styles.description}>Our experience—<b>gained from 15 years of running a successful nearshore software development company</b> and helping countless other businesses succeed through innovation—is our main guarantee that we'll help you plan, design and develop a winning digital product.</p>
      </div>

      <div className={styles.imageContainer}>
        <img src={icon} alt="" className={styles.image} />
      </div>
      <div className={styles.imageContainerMobile}>
        <img src={iconMobile} alt="" className={styles.imageMobile} />
      </div>
    </div>
  );
};

export default HighPerformingNearshore;
